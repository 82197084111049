body {
  display: flex;
  position: absolute;
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  font-family: Raleway, sans-serif;
  font-feature-settings: 'pnum' on, 'lnum' on;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
}

#root {
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
}

.scroll-container {
  scroll-padding: 10px;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: inherit;
}

::-webkit-scrollbar-thumb {
  background: #EEBF4D;
}

::-webkit-scrollbar-thumb:vertical {
  border-left: 4px solid #FFF;
  border-radius: 10px 0 0 10px;
}

::-webkit-scrollbar-thumb:horizontal {
  border-top: 4px solid #FFF;
  border-radius: 10px 10px 0 0;
}

::-webkit-scrollbar-thumb:hover {
  background: #d4a73c;
}
